@layer base {
  .sui-u-main {
    /* TODO: Remove entire block when KANRI_ENABLE_GLOBAL_NAVIGATION is in live */
    min-width: 72rem;
    min-height: calc(100vh - 6.9375rem);
  }
  .sui-u-main-global {
    width: calc(100vw - 3.5rem);
    height: 100vh;
    overflow: auto;
  }
  .inner-main {
    min-width: 86.5rem;
    min-height: calc(100vh - 3.3125rem);
  }
  .sui-c-footer {
    min-width: 86.5rem;
  }
  .w-400 {
    width: 25rem;
  }
  /* TODO: Remove if user-pop-out is available in seibun */
  .user-pop-out {
    box-shadow: 0 0.075rem 0.225rem 0px #0000001A;
    background-color: #ffffff;
  }
  .user-pop-out:before {
    content: '';
    display: block;
    box-shadow: 0 0.4rem 0.9rem 0px #00000021;
  }
}

/*Custom css for List filtering*/
.select-style {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: transparent !important;
  background-image: none !important;
}

.modal-filter .modal-filter-body {
  min-height: calc(100vh - 10.7rem);
}

.modal-filter .modal-filter-body .modal-filter-accordions {
  background: none;
}

.modal-filter .modal-filter-body .modal-filter-accordions > div {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.modal-filter .modal-filter-field {
  padding: 14px 16px;
}

.modal-filter .modal-filter-field > div > h3 {
  font-size: 16px;
}

.filter-criteria-container {
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
}

.filter-criteria.filter-criteria-parent {
  margin-right: 0.5rem;
}

.filter-criteria .filter-criteria-pill,
.filter-criteria-clear-all {
  width: fit-content;
  margin-bottom: 10px;
}

.filter-criteria .filter-criteria-pill div {
  padding: 0;
}

.filter-criteria .filter-criteria-pill div span {
  cursor: pointer;
  padding-left: 5px;
}

.filter-criteria .filter-criteria-pill div span svg {
  width: 15px;
  height: 15px;
}

.modal-filter
  .modal-filter-field.contributorLocaleIn
  + div
  > div
  > div:last-child
  > div:first-child {
  margin: 5px 0;
  max-height: 240px;
  overflow-y: auto;
}

.modal-filter .checkbox-group.contributorLocaleIn {
  grid-gap: 0;
}

.modal-filter .checkbox-group.contributorLocaleIn > div {
  padding: 0.4rem 0;
}

.modal-filter .checkbox-group.contributorLocaleIn > div:has(input:checked) {
  background: #f4f9f2;
}

.modal-filter {
  height: 100vh;
}
